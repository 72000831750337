export const color = {
  white: "#fff",
  blue10: "#F5F7F8",
  blue50: "#F5F8FF",
  blue100: "#e6f4ff",
  blue200: "#bae0ff",
  blue300: "#91caff",
  blue400: "#69b1ff",
  blue500: "#4096ff",
  blue600: "#1677ff",
  blue700: "#0958d9",
  blue800: "#003eb3",
  blue900: "#002c8c",
  blue1000: "#001d66",
  grey50: "#F6F6F6",
  grey100: "#F0F0F0",
  grey200: "#EBEBEB",
  grey300: "#E1E1E1",
  grey400: "#D5D5D5",
  grey500: "#BBBBBB",
  grey600: "#898989",
  grey700: "#565656",
  grey800: "#3F3F3F",
  grey900: "#252525",
  black: "#191919",
  green100: "#F6FFED",
  green200: "#D9F7BE",
  green300: "#B7EB8F",
  green400: "#95DE64",
  green500: "#73CF36",
  green600: "#52C41A",
  green700: "#389E0D",
  green800: "#237804",
  green900: "#135200",
  green1000: "#092B00",
  gold100: "#FFFBE6",
  gold200: "#FFF1B8",
  gold300: "#FFF1B8",
  gold400: "#FFD666",
  gold500: "#FFCA3A",
  gold600: "#FAAD14",
  gold700: "#D48806",
  gold800: "#AD6800",
  gold900: "#874D00",
  gold1000: "#613400",
  red100: "#FFF1EF",
  red200: "#FFCCC7",
  red300: "#FFA39E",
  red400: "#FFA39E",
  red500: "#FFA39E",
  red600: "#F5222D",
  red700: "#CF1322",
  red800: "#A8071A",
  red900: "#820014",
  red1000: "#5C0011",
  brand10: "#F5F7F8",
  brand50: "#F5F8FF",
  brand100: "#E6F4FF",
  brand200: "#BAE0FF",
  brand300: "#91CAFF",
  brand400: "#69B1FF",
  brand500: "#4096FF",
  brand600: "#0958D9",
  brand700: "#0958D9",
  brand800: "#003EB3",
  brand900: "#002C8C",
  brand1000: "#001D66",
  neutral50: "#F6F6F6",
  neutral100: "#F0F0F0",
  neutral200: "#EBEBEB",
  neutral300: "#E1E1E1",
  neutral400: "#D5D5D5",
  neutral500: "#BBBBBB",
  neutral600: "#898989",
  neutral700: "#565656",
  neutral800: "#3F3F3F",
  neutral900: "#252525",
  information100: "#E6F4FF",
  information200: "#BAE0FF",
  information300: "#91CAFF",
  information400: "#69B1FF",
  information500: "#4096FF",
  information600: "#1677FF",
  information700: "#0958D9",
  information800: "#003EB3",
  information900: "#002C8C",
  information1000: "#001D66",
  success100: "#F6FFED",
  success200: "#D9F7BE",
  success300: "#B7EB8F",
  success400: "#95DE64",
  success500: "#73CF36",
  success600: "#52C41A",
  success700: "#389E0D",
  success800: "#237804",
  success900: "#135200",
  success1000: "#092B00",
  warning100: "#FFFBE6",
  warning200: "#FFF1B8",
  warning300: "#FFF1B8",
  warning400: "#FFD666",
  warning500: "#FFCA3A",
  warning600: "#FAAD14",
  warning700: "#D48806",
  warning800: "#AD6800",
  warning900: "#874D00",
  warning1000: "#613400",
  danger100: "#FFF1EF",
  danger200: "#FFCCC7",
  danger300: "#FFA39E",
  danger400: "#FFA39E",
  danger500: "#FFA39E",
  danger600: "#F5222D",
  danger700: "#CF1322",
  danger800: "#A8071A",
  danger900: "#820014",
  danger1000: "#5C0011",
  textPrimary: "#252525",
  textSecondary: "#3f3f3f",
  textTertiary: "#565656",
  textQuaternary: "#898989",
  textInformation: "#1677FF",
  textSuccess: "#52C41A",
  textDanger: "#4096FF",
  textWarning: "#FAAD14",
  textDisabled: "#bbbbbb",
  textInverse: "#ffffff",
  textInteractivePrimaryDefault: "#1677ff",
  textInteractivePrimaryHover: "#0958d9",
  textInteractivePressed: "#003eb3",
  textInteractiveSecondaryDefault: "#565656",
  textInteractiveSecondaryPressed: "#e1e1e1",
  textInteractiveInformationDefault: "#0958d9",
  textInteractive: "#0958d9",
};

export const fontFamily = {
  primary: '"Navigo"',
  secondary: '"DM Serif Display"',
};

const customNewTypography = {
  display1: {
    fontFamily: fontFamily.primary,
    fontSize: "72px",
    letterSpacing: "3%",
    lineHeight: "88px",
    fontWeight: "400",
  },
  display2: {
    fontFamily: fontFamily.primary,
    fontSize: "64px",
    letterSpacing: "3%",
    lineHeight: "78px",
    fontWeight: "400",
  },
  display3: {
    fontFamily: fontFamily.primary,
    fontSize: "56px",
    letterSpacing: "3%",
    lineHeight: "70px",
    fontWeight: "400",
  },
  display4: {
    fontFamily: fontFamily.primary,
    fontSize: "48px",
    letterSpacing: "3%",
    lineHeight: "60px",
    fontWeight: "400",
  },
  display5: {
    fontFamily: fontFamily.primary,
    fontSize: "40px",
    letterSpacing: "3%",
    lineHeight: "50px",
    fontWeight: "400",
  },
  display6: {
    fontFamily: fontFamily.primary,
    fontSize: "32px",
    letterSpacing: "3%",
    lineHeight: "40px",
    fontWeight: "400",
  },
  xl: {
    fontFamily: fontFamily.primary,
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "400",
  },
  lg: {
    fontFamily: fontFamily.primary,
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  md: {
    fontFamily: fontFamily.primary,
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
  },
  sm: {
    fontFamily: fontFamily.primary,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
  },
  xs: {
    fontFamily: fontFamily.primary,
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
  },
  smallLabel: {
    fontFamily: fontFamily.primary,
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: "400",
  },
};

const customHeaders = {
  h1: {
    fontSize: "38px",
    lineHeight: "56px",
    fontWeight: "500",
  },
  h2: {
    fontSize: "34px",
    lineHeight: "48px",
    fontWeight: "500",
  },
  h3: {
    fontSize: "30px",
    lineHeight: "42px",
    fontWeight: "500",
  },
  h4: {
    fontSize: "26px",
    lineHeight: "38px",
    fontWeight: "500",
  },
  h5: {
    fontSize: "22px",
    lineHeight: "32px",
    fontWeight: "500",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "500",
  },
};

export const breakpoints = {
  xs: 0,
  mobile: 0, // 360
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
};

export const spacings = {
  spacing0: "0px",
  spacing2: "2px",
  spacing4: "4px",
  spacing8: "8px",
  spacing12: "12px",
  spacing16: "16px",
  spacing20: "20px",
  spacing24: "24px",
  spacing32: "32px",
  spacing36: "36px",
  spacing40: "40px",
  spacing48: "48px",
  spacing56: "56px",
  spacing64: "64px",
  spacing72: "72px",
  spacing80: "80px",
  spacing96: "96px",
  spacing112: "112px",
  spacing128: "128px",
  spacing144: "144px",
  spacing160: "160px",
};

export const contentWidth = {
  desktop: "1120px",
  laptop: "864px",
  tablet: "688px",
  mobile: "328px",
};

export const theme = {
  typography: {
    fontFamily: fontFamily.primary,
    ...customNewTypography,
    ...customHeaders,
  },
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
};
