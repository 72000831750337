import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, Typography, Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as api from "api/admin/template";
import { EmailTemplate, EmailTemplateListFilter } from "api/types";
import { useLoading } from "components/LoadingProvider";

const initialFilter = {
  tier: undefined,
} as EmailTemplateListFilter;

const EmailTemplateList = () => {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplate | undefined
  >(undefined);
  const [filter, setFilter] = useState(initialFilter);
  const { setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const fetchEmailTemplates = useCallback(
    async (filter: EmailTemplateListFilter) => {
      setIsLoading(true);
      const res = await api.getEmailTemplates(filter);
      if (res.status === 200) {
        setEmailTemplates(res.data);
      }
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const fetchEmailTemplate = useCallback(
    async (emailTemplateUuid: string) => {
      setIsLoading(true);
      const res = await api.getEmailTemplate(emailTemplateUuid);
      if (res.status === 200) {
        setSelectedEmailTemplate(res.data);
      }
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const deleteEmailTemplate = async (emailTemplateUuid: string) => {
    setIsLoading(true);
    const res = await api.deleteEmailTemplate(emailTemplateUuid);
    if (res.status === 200) {
      setSelectedEmailTemplate(undefined);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    var newFilter = { ...filter };
    var tier = searchParams.get("tier") ?? undefined;
    if (tier !== filter.tier) {
      newFilter.tier = tier;
      setFilter(newFilter);
    }

    fetchEmailTemplates(newFilter);
  }, [searchParams, fetchEmailTemplates, filter]);

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/email-templates/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (params: any) => {
    const handleOnClick = async (e: any) => {
      await deleteEmailTemplate(params.id);
      return navigate(0);
    };

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Delete
      </Button>
    );
  };

  const PreviewButton = (params: any) => {
    const emailTemplateUuid = params.id;
    const handleOnRowClick = async () => {
      fetchEmailTemplate(emailTemplateUuid);
    };

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnRowClick}
      >
        Preview
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "emailTemplateId", headerName: "ID", width: 50 },
    { field: "emailSubject", headerName: "Subject", width: 300 },
    { field: "emailSenderName", headerName: "Sender", width: 150 },
    { field: "senderEmail", headerName: "From", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "tier", headerName: "Tier", width: 50 },
    {
      field: "imageId",
      headerName: "Image",
      width: 50,
      valueGetter: (_, row) => row?.templateImage_TemplateImageId,
    },
    {
      field: "Edit",
      headerName: "",
      width: 120,
      renderCell: DetailButton,
    },
    {
      field: "Delete",
      headerName: "",
      width: 120,
      renderCell: DeleteButton,
    },
    {
      field: "Preview",
      headerName: "",
      width: 120,
      renderCell: PreviewButton,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {filter?.tier && `Tier ${filter.tier} `}Email Template List
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={selectedEmailTemplate ? 8 : 12}>
          <DataGrid
            rows={emailTemplates}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50]}
            getRowId={(row) => row.emailTemplateUuid}
          />
        </Grid>
        {selectedEmailTemplate && (
          <Grid item xs={4} sx={{ mt: 7 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedEmailTemplate.emailBody,
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EmailTemplateList;
