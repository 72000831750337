import axios, { AxiosResponse } from "axios";
import {
  ValidationError,
  EmailTemplate,
  GetEmailTemplateDetail,
  EmailTemplateListFilter,
} from "api/types";
import { adminConfig } from "api/const";

const getEmailTemplates = async (
  filter?: EmailTemplateListFilter
): Promise<AxiosResponse<EmailTemplate[]>> => {
  const url = `/api/v1/email-template`;
  let res = null;
  try {
    const config = {
      ...adminConfig,
      params: filter,
    };
    res = await axios.get(url, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const getEmailTemplate = async (
  emailTemplateUuid: string
): Promise<AxiosResponse<GetEmailTemplateDetail>> => {
  const url = `/api/v1/email-template/${emailTemplateUuid}`;
  let res = null;
  try {
    res = await axios.get(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const deleteEmailTemplate = async (
  emailTemplateUuid: string
): Promise<AxiosResponse<EmailTemplate>> => {
  const url = `/api/v1/email-template/${emailTemplateUuid}`;
  let res = null;
  try {
    res = await axios.delete(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

interface PostEmailTemplatePayload {
  image?: File;
  message?: string;
  tier?: number;
  systemMessage?: string;
  userMessages?: string[];
}
const postEmailTemplate = async (
  payload: PostEmailTemplatePayload
): Promise<AxiosResponse<EmailTemplate>> => {
  const url = "/api/v1/email-template";
  let res = null;
  try {
    const config = {
      ...adminConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // paramsSerializer: {
      //   indexes: null,
      // }
    };
    // const formattedPayload = {...payload};
    // formattedPayload.userMessages = payload.userMessages?.join('||');
    res = await axios.post(
      url,
      { ...payload, userMessages: payload.userMessages?.join("||") },
      config
    );

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const postEmailTemplateRefresh = async (
  emailTemplateUuid: string
): Promise<AxiosResponse<GetEmailTemplateDetail>> => {
  const url = `/api/v1/email-template/${emailTemplateUuid}/refresh`;
  let res = null;
  try {
    const config = {
      ...adminConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    // const payload: PostEmailTemplateRefreshShape = {
    //   email_template_uuid: emailTemplateUuid
    // };
    res = await axios.post(url, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const putEmailTemplate = async (
  emailTemplate: EmailTemplate
): Promise<AxiosResponse<EmailTemplate>> => {
  const url = `/api/v1/email-template/${emailTemplate.emailTemplateUuid}`;
  let res = null;
  try {
    const config = {
      ...adminConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.put(url, emailTemplate, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

interface GetTier2MessagesResponse {
  systemMessage: string;
  userMessages: string[];
}
const getTier2Messages = async (): Promise<
  AxiosResponse<GetTier2MessagesResponse>
> => {
  const url = `/api/v1/email-template/tier2/message`;
  let res = null;
  try {
    res = await axios.get(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export {
  getEmailTemplates,
  getEmailTemplate,
  deleteEmailTemplate,
  postEmailTemplate,
  postEmailTemplateRefresh,
  putEmailTemplate,
  getTier2Messages,
};
