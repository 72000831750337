import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, TextField, Box } from "@mui/material";

import { Email } from "api/types";
import { getEmail } from "api/admin/email";
import { useLoading } from "components/LoadingProvider";
import { convertUTCToLocalTime } from "utils";

const EmailDetail = () => {
  const { emailUuid } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState<Email | undefined>(undefined);
  const { setIsLoading } = useLoading();

  const fetchEmail = useCallback(async () => {
    if (!emailUuid) {
      return;
    }
    setIsLoading(true);

    const res = await getEmail(emailUuid);
    if (res.status === 200) {
      setEmail(res.data);
    }
    setIsLoading(false);
  }, [setIsLoading, emailUuid]);

  useEffect(() => {
    fetchEmail();
  }, [fetchEmail]);

  if (!email) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <TextField
              margin="normal"
              label="Email UUID"
              variant="outlined"
              fullWidth
              name="email-uuid"
              value={email.emailUuid}
              disabled
            />
            <TextField
              margin="normal"
              label="Status"
              variant="outlined"
              fullWidth
              value={email.status}
              name="email-status"
            />
            <TextField
              margin="normal"
              label="Phishee Email"
              variant="outlined"
              fullWidth
              value={email.phishee.user.email}
            />
            <TextField
              margin="normal"
              label="Phishee Name"
              variant="outlined"
              fullWidth
              value={`${email.phishee.user.firstName} ${email.phishee.user.lastName}`}
            />
            <TextField
              margin="normal"
              label="Job Function"
              variant="outlined"
              fullWidth
              value={email.phishee.jobFunction}
            />
            <TextField
              margin="normal"
              label="Phishee Created"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(email.phishee.createdDate)}
            />
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={email.phishee.account.accountName}
            />
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={email.phishee.account.accountName}
            />
            <TextField
              margin="normal"
              label="Account Created"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(email.phishee.account.createdDate)}
            />
            <TextField
              margin="normal"
              label="Prepared Email Subject"
              variant="outlined"
              fullWidth
              value={email.preparedEmailSubject}
            />
            <TextField
              value={email.preparedEmailBody}
              multiline
              maxRows={20}
              fullWidth
              disabled
            />
          </Box>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(`/admin/phishees/${email.phishee.phisheeUuid}`)
            }
            sx={{ mr: 2 }}
          >
            Open Phishee
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(`/admin/accounts/${email.phishee.account.accountUuid}`)
            }
            sx={{ mr: 2 }}
          >
            Open Account
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/admin/email-templates/${email.emailTemplate.emailTemplateUuid}`
              )
            }
          >
            Open Email Template
          </Button>
        </Grid>
        <Grid item xs={6}>
          <div dangerouslySetInnerHTML={{ __html: email.preparedEmailBody }} />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailDetail;
