import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getTier2Messages, postEmailTemplate } from "api/admin/template";
import { useNavigate } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Tier2TemplateGenerator = () => {
  const navigate = useNavigate();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>("");
  const [systemMessage, setSystemMessage] = useState<string | undefined>(
    undefined
  );
  const [userMessages, setUserMessages] = useState<string[] | undefined>(
    undefined
  );
  const { setIsLoading } = useLoading();

  useEffect(() => {
    const fetchMessages = async () => {
      setIsLoading(true);

      const res = await getTier2Messages();
      if (res.status === 200) {
        setSystemMessage(res.data.systemMessage);
        setUserMessages(res.data.userMessages);
      }
      setIsLoading(false);
    };

    if (userMessages === undefined && systemMessage === undefined) {
      fetchMessages();
    }

    if (!uploadedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(uploadedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [uploadedFile, systemMessage, userMessages, setIsLoading]);

  const handleUploadClick = (event: any) => {
    var file = event.target.files?.[0]; // Add null check here
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!uploadedFile) {
      console.log("No file or token", uploadedFile);
      return;
    }
    setIsLoading(true);
    const response = await postEmailTemplate({
      image: uploadedFile,
      tier: 2,
      systemMessage,
      userMessages,
    });
    setIsLoading(false);
    if (response.status === 201) {
      return navigate(
        `/admin/email-templates/${response.data.emailTemplateUuid}`
      );
    }
  };

  return (
    <>
      <Box>
        <h1>Teir 2 Template Generator</h1>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={handleUploadClick}
              >
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </Box>
            <TextField
              key={`system-message`}
              label="System Message"
              multiline
              fullWidth
              rows={4}
              value={systemMessage}
              onChange={(e) => setSystemMessage(e.target.value)}
              sx={{ mb: 3 }}
            />
            {userMessages?.map((message, index) => (
              <TextField
                key={index}
                label={`User Message ${index + 1}`}
                multiline
                fullWidth
                rows={4}
                value={message}
                onChange={(e) => {
                  const newMessages = [...userMessages];
                  newMessages[index] = e.target.value;
                  setUserMessages(newMessages);
                }}
                sx={{ mb: 3 }}
              />
            ))}
            <Box sx={{ mt: 3 }}>
              <Button type="submit" variant="contained" sx={{ mr: 3 }}>
                Generate
              </Button>
              {userMessages && (
                <Button
                  variant="contained"
                  onClick={(e) => setUserMessages([...userMessages, ""])}
                >
                  Add User Message
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {uploadedFile && (
            <Box>
              <img src={preview} alt="Preview" />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Tier2TemplateGenerator;
