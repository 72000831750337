import React, { useState } from "react";
import { onLogin } from "api/auth";
import { useCookies } from "react-cookie";
import { redirect, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { COOKIES } from "const";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookie, setCookie] = useCookies([
    COOKIES.ACCOUNT_ACCESS_TOKEN,
    COOKIES.ACCOUNT_FIRST_NAME,
    COOKIES.ACCOUNT_LAST_NAME,
    COOKIES.ACCOUNT_UUID,
    COOKIES.ACCOUNT_EMAIL,
  ]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const res = await onLogin(email, password);
    if (res.status !== 200) {
      setError("Invalid email or password");
      return;
    }
    setCookie(COOKIES.ACCOUNT_ACCESS_TOKEN, res.data.token, { path: "/" });
    setCookie(COOKIES.ACCOUNT_FIRST_NAME, res.data.firstName, { path: "/" });
    setCookie(COOKIES.ACCOUNT_LAST_NAME, res.data.lastName, { path: "/" });
    setCookie(COOKIES.ACCOUNT_UUID, res.data.accountUuid, { path: "/" });
    setCookie(COOKIES.ACCOUNT_EMAIL, res.data.email, { path: "/" });

    return redirect("/account/dashboard");
  };

  return (
    <Container component="main">
      {cookie.accountAccessToken && (
        <Navigate to="/account/dashboard" replace={true} />
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          alignItems: "center",
          margin: "auto",
          marginTop: 8,
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography variant="h5" component="h1" textAlign="center">
          Sign In
        </Typography>
        <Box>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
        </Box>
        <Button type="submit" variant="contained" fullWidth>
          Sign In
        </Button>
        <Button
          component={Link}
          to="/signup"
          variant="outlined"
          color="primary"
          fullWidth
        >
          Sign Up
        </Button>
        <Typography textAlign="center">
          Forgot password?{" "}
          <a href="/reset-password" style={{ textDecoration: "none" }}>
            Reset password
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default LoginForm;
